<template>
  <div class="form-elements">
    <div class="row">
      <div class="col-md-12">
        <vuestic-tabs class="tabs" :names="['Configuraciones', 'Contraseñas']">
          <div slot="Configuraciones">
            <vuestic-widget :headerText="'view.event.configEvent' | translate">
              <form @submit.prevent="editSubmit('form-dataEvent')" autocomplete="off" data-vv-scope="form-dataEvent">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right"
                          :class="{ 'has-error': errors.has('name'), 'valid': isSuccessNameValid }">
                          <div class="input-group">
                            <input id="name" name="name" v-model="name" v-validate="'required'">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="name">{{ 'view.event.fields.name' | translate }}</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('name')" class="help text-danger">{{ errors.first('name')
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('start') }">
                          <div class="input-group">
                            <vuestic-date-picker id="date-picker-time" :config="{ enableTime: true }" v-model="start" />
                            <label class="control-label" for="date-picker-time">{{ $t('view.event.fields.start')
                            }}</label>
                            <i class="bar"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('end') }">
                          <div class="input-group">
                            <vuestic-date-picker id="date-picker-time" :config="{ enableTime: true }" v-model="end" />
                            <label class="control-label" for="date-picker-time">{{ $t('view.event.fields.end')
                            }}</label>
                            <i class="bar"></i>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right"
                          :class="{ 'has-error': errors.has('max_virtual_credit') }">
                          <div class="input-group">
                            <input id="max_virtual_credit" name="max_virtual_credit" v-model="max_virtual_credit"
                              v-validate="'required|numeric'">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="max_virtual_credit">{{
                                'view.event.fields.max_virtual_credit' | translate
                            }}</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('max_virtual_credit')" class="help text-danger">{{
                                errors.first('max_virtual_credit')
                            }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group with-icon-right"
                          :class="{ 'has-error': errors.has('max_normal_credit') }">
                          <div class="input-group">
                            <input id="max_normal_credit" name="max_normal_credit" v-model="max_normal_credit"
                              v-validate="'required|numeric'">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="max_normal_credit">{{
                                'view.event.fields.max_normal_credit'
                                | translate
                            }}</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('max_normal_credit')" class="help text-danger">{{
                                errors.first('max_normal_credit')
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right"
                          :class="{ 'has-error': errors.has('max_virtual_credit') }">
                          <div class="input-group">
                            <input id="min_virtual_credit" name="min_virtual_credit" v-model="min_virtual_credit"
                              v-validate="'required|numeric'">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="min_virtual_credit">{{
                                'view.event.fields.min_virtual_credit' | translate
                            }}</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('min_virtual_credit')" class="help text-danger">{{
                                errors.first('min_virtual_credit')
                            }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group with-icon-right"
                          :class="{ 'has-error': errors.has('max_virtual_credit') }">
                          <div class="input-group">
                            <input id="min_normal_credit" name="min_normal_credit" v-model="min_normal_credit"
                              v-validate="'required|numeric'">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="min_normal_credit">{{
                                'view.event.fields.min_normal_credit'
                                | translate
                            }}</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('min_normal_credit')" class="help text-danger">{{
                                errors.first('min_normal_credit')
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right"
                          :class="{ 'has-error': errors.has('max_virtual_credit') }">
                          <div class="input-group">
                            <input id="max_total_credit" name="max_total_credit" v-model="max_total_credit"
                              v-validate="'required|numeric'">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="max_total_credit">{{ 'view.event.fields.max_total_credit'
                                |
                                translate
                            }}</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('max_total_credit')" class="help text-danger">{{
                                errors.first('max_total_credit')
                            }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('active') }">
                          <div class="input-group">
                            <vuestic-switch v-model="active">
                              <span slot="trueTitle">{{ 'view.event.fields.enabled' | translate }}</span>
                              <span slot="falseTitle">{{ 'view.event.fields.disabled' | translate }}</span>
                            </vuestic-switch>
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>

                            <small v-show="errors.has('active')" class="help text-danger">{{ errors.first('active')
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" v-show="statusEvent != 3">
                        <button class="btn btn-primary btn-sm" type="submit">{{ $t('view.event.save') }}</button>
                      </div>
                      <div class="col">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">

                    <div class="row">

                      <div class="col">
                        <div class="form-group with-icon-right">
                          <multiselect v-model="id_type_event" deselect-label="Selected Element" label="name"
                            placeholder="Select an Element" :options="TypeEvent" :searchable="false"
                            :allow-empty="false">
                            <template slot="singleLabel" slot-scope="{ option }">
                              {{ 'view.event.fields.type_event' | translate }}
                              <label style="font-weight:600">{{ option.name }}</label>
                            </template>
                          </multiselect>
                        </div>
                      </div>

                    </div>
                    <div class="row">

                      <div class="col">
                        <div class="form-group with-icon-right">
                          <multiselect v-model="timezone" deselect-label="Selected Element" label="name"
                            placeholder="Select an Element" :options="timezones" :searchable="false"
                            :allow-empty="false">
                            <template slot="singleLabel" slot-scope="{ option }">
                              {{ 'view.event.fields.timezone' | translate }}
                              <label style="font-weight:600">{{ option.name }}</label>
                            </template>
                          </multiselect>
                        </div>
                      </div>

                    </div>
                    <div class="row">

                      <div class="col">
                        <div class="form-group with-icon-right">
                          <multiselect v-model="currency" deselect-label="Selected Element" label="name"
                            placeholder="Select an Element" :options="currencies" :searchable="false"
                            :allow-empty="false">
                            <template slot="singleLabel" slot-scope="{ option }">
                              {{ 'view.event.fields.currency' | translate }}
                              <label style="font-weight:600">{{ option.name }}</label>
                            </template>
                          </multiselect>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <input id="slug" name="slug" v-model="slug">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="slug">Slug</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('min_normal_credit')" class="help text-danger">{{
                                errors.first('slug')
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <input id="slug" name="slug" v-model="fee_topup">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="slug">Fee de Recargas</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('min_normal_credit')" class="help text-danger">{{
                                errors.first('slug')
                            }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <input id="slug" name="slug" v-model="fee_refund">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="slug">Fee de Devolucion</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('min_normal_credit')" class="help text-danger">{{
                                errors.first('slug')
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <vuestic-switch name="allow_refund" id="allow_refund" v-model="allow_refund">
                              <span slot="trueTitle">Permitir Devolucion</span>
                              <span slot="falseTitle">No Permitir</span>
                            </vuestic-switch>

                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <vuestic-switch name="allow_refund" id="allow_refund" v-model="allow_product_free">
                              <span slot="trueTitle">Permitir Productos Gratis</span>
                              <span slot="falseTitle">No Permitir</span>
                            </vuestic-switch>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <input id="slug" name="max_total_free_product" v-model="max_total_free_product">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="slug">Máximo Total de Productos Gratis</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('max_total_free_product')" class="help text-danger">{{
                                errors.first('max_total_free_product')
                            }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <input id="max_cant_free_product" name="max_cant_free_product"
                              v-model="max_cant_free_product">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="slug">Cantidad Máxima por cada Producto Gratis</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('max_cant_free_product')" class="help text-danger">{{
                                errors.first('max_cant_free_product')
                            }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <input id="usd_bcv" name="usd_bcv" v-model="usd_bcv">
                            <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                            <i class="fa fa-check valid-icon icon-right input-icon"></i>
                            <label class="control-label" for="slug">Tasa BCV</label>
                            <i class="bar"></i>
                            <small v-show="errors.has('usd_bcv')" class="help text-danger">{{
                                errors.first('usd_bcv')
                            }}</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <vuestic-switch name="allow_refund" id="allow_refund" v-model="allow_checkin">
                              <span slot="trueTitle">Permitir Checkin</span>
                              <span slot="falseTitle">No Permitir</span>
                            </vuestic-switch>

                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('slug') }">
                          <div class="input-group">
                            <vuestic-switch name="allow_refund" id="allow_refund" v-model="checkin_type">
                              <span slot="trueTitle">Checkin Normal</span>
                              <span slot="falseTitle">Checkin BiPass</span>
                            </vuestic-switch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </vuestic-widget>
          </div>
          <div slot="Contraseñas">
            <vuestic-widget :headerText="'view.profile.updatePassword' | translate">
              <form @submit.prevent="updatePasswordForm('form-Password')" autocomplete="off"
                data-vv-scope="form-Password">
                <div class="row">
                  <div class="col-md-12">
                    <fieldset>
                      <div class="row">
                        <div class="col-md-12 with-icon-right">
                          <div class="form-group form-group-w-btn"
                            :class="{ 'has-error': errors.has('passwordEvent') }">
                            <div class="input-group">
                              <input :type="passwordType" id="passwordEvent" name="passwordEvent"
                                v-model="passwordEvent" v-validate="'required'">
                              <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                              <i class="fa fa-check valid-icon icon-right input-icon"></i>
                              <label class="control-label" for="passwordEvent">{{ 'view.profile.fields.passwordEvent' |
                                  translate
                              }}</label>
                              <i class="bar"></i>
                              <small v-show="errors.has('passwordEvent')" class="help text-danger">{{
                                  errors.first('passwordEvent')
                              }}</small>
                            </div>
                            <div class="btn btn-micro btn-primary" v-on:click="showPassword()"> <i class="fa fa-eye"
                                aria-hidden="true"></i></div>
                          </div>
                        </div>
                        <div class="col-md-12 with-icon-right">
                          <div class="form-group with-icon-right" :class="{ 'has-error': errors.has('newPassword') }">
                            <div class="input-group">
                              <input type="password" id="newPassword" name="newPassword" v-model="newPassword"
                                v-validate="'required|min:6'">
                              <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                              <i class="fa fa-check valid-icon icon-right input-icon"></i>
                              <label class="control-label" for="newPassword">{{ 'view.profile.fields.newPassword' |
                                  translate
                              }}</label>
                              <i class="bar"></i>
                              <small v-show="errors.has('newPassword')" class="help text-danger">{{
                                  errors.first('newPassword')
                              }}</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 with-icon-right">
                          <div class="form-group with-icon-right"
                            :class="{ 'has-error': errors.has('confirmPassword') }">
                            <div class="input-group">
                              <input type="password" id="confirmPassword" name="confirmPassword"
                                v-model="confirmPassword" v-validate="'required|confirmed:newPassword'">
                              <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                              <i class="fa fa-check valid-icon icon-right input-icon"></i>
                              <label class="control-label" for="confirmPassword">{{
                                  'view.profile.fields.confirmPassword'
                                  | translate
                              }}</label>
                              <i class="bar"></i>
                              <small v-show="errors.has('confirmPassword')" class="help text-danger">{{
                                  errors.first('confirmPassword')
                              }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div class="col-md-3 offset-md-12" v-show="statusEvent === 1">
                      <button class="btn btn-primary btn-sm" type="submit">{{ $t('view.profile.updatePassword')
                      }}</button>
                    </div>
                  </div>
                </div>
              </form>
            </vuestic-widget>
          </div>
        </vuestic-tabs>

      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {
  mapState,
  mapActions
} from 'vuex'
import moment from 'moment'

export default {
  name: 'config-event',
  components: {
    Multiselect
  },
  data() {
    return {
      TypeEvent: [],
      timezones: [],
      currencies: [],
      active: 1,
      id_type_event: '',
      timezone: '',
      currency: '',
      passwordEvent: '',
      passwordType: 'password',
      newPassword: '',
      confirmPassword: '',
      name: '',
      start: moment().format('YYYY-MM-DD HH:mm'),
      end: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD HH:mm'),
      max_virtual_credit: '',
      max_normal_credit: '',
      min_virtual_credit: '',
      min_normal_credit: '',
      max_total_credit: '',
      slug: '',
      fee_topup: '',
      fee_refund: '',
      allow_refund: 1,
      allow_product_free: 1,
      allow_checkin: 0,
      checkin_type: 1,
      max_total_free_product: 5,
      max_cant_free_product: 1,
      usd_bcv: 0
    }
  },
  mounted() {
    this.loadEventConfig({
      id: this.eventConfig.id
    })
      .then(data => {
        (this.active = data.active),
          (this.id_type_event = data.type_event),
          (this.timezone = data.time_zone),
          (this.currency = data.config_event.currency),
          (this.name = data.name),
          (this.start = data.start),
          (this.end = data.end),
          (this.max_virtual_credit = data.config_event.max_virtual_credit),
          (this.max_normal_credit = data.config_event.max_normal_credit),
          (this.min_virtual_credit = data.config_event.min_virtual_credit),
          (this.min_normal_credit = data.config_event.min_normal_credit),
          (this.max_total_credit = data.config_event.max_total_credit),
          (this.config_event_id = data.config_event.id),
          (this.passwordEvent = data.password),
          (this.slug = data.config_event.slug),
          (this.fee_topup = data.config_event.fee_topup),
          (this.fee_refund = data.config_event.fee_refund),
          (this.allow_refund = data.config_event.allow_refund),
          (this.allow_product_free = data.config_event.allow_free_product),
          (this.allow_checkin = data.config_event.allow_checkin),
          (this.checkin_type = data.config_event.checkin_type),
          (this.max_total_free_product = data.config_event.max_total_free_product),
          (this.max_cant_free_product = data.config_event.max_cant_free_product),
          this.usd_bcv = data.config_event.usd_bcv



      })
      .catch(data => {
        this.addToastMessage({
          text: data.message,
          type: 'warning'
        })
      })
    this.loadTypeEvents()
      .then(data => {
        this.TypeEvent = data
      })
      .catch(data => {
        this.error = data.message
      })
    this.loadTimezones()
      .then(data => {
        this.timezones = data
      })
      .catch(data => {
        this.error = data.message
      })
    this.loadCurrencies()
      .then(data => {
        this.currencies = data
      })
      .catch(data => {
        this.error = data.message
      })
  },
  methods: {
    ...mapActions([
      'updateEvent',
      'loadEventConfig',
      'loadTypeEvents',
      'loadCurrencies',
      'loadTimezones',
      'addToastMessage',
      'updatePasswordEvent'
    ]),
    showPassword() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    updatePasswordForm(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          let form = {
            id: this.eventConfig.id,
            actualPassword: this.passwordEvent,
            newPassword: this.newPassword,
            confirmPassword: this.confirmPassword,
          }
          this.updatePasswordEvent(form)
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
            })
            .catch(data => {
              this.error = data.message
            })
        }
      })
    },
    editSubmit(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (result) {
          let form = {
            id: this.eventConfig.id,
            name: this.name,
            start: this.start,
            end: this.end,
            id_type_event: this.id_type_event.id,
            timezone: this.timezone.id,
            currency: this.currency.iso,
            max_virtual_credit: this.max_virtual_credit,
            max_normal_credit: this.max_normal_credit,
            min_virtual_credit: this.min_virtual_credit,
            min_normal_credit: this.min_normal_credit,
            max_total_credit: this.max_total_credit,
            slug: this.slug,
            fee_refund: this.fee_refund,
            fee_topup: this.fee_topup,
            allow_refund: this.allow_refund,
            allow_free_product: this.allow_product_free,
            allow_checkin: this.allow_checkin,
            checkin_type: this.checkin_type,
            max_total_free_product: this.max_total_free_product,
            max_cant_free_product: this.max_cant_free_product,
            active: this.active,
            config_event_id: this.config_event_id,
            usd_bcv: this.usd_bcv
          }

          this.updateEvent(form)
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
            })
            .catch(data => {
              this.error = data.message
            })
        }
      })
    },
    clear(field) {
      this[field] = ''
    },
    back() {
      this.$router.go(-1)
      // this.$router.push('/')
    }
  },
  computed: {
    ...mapState({
      eventConfig: state => state.event.event,
      statusEvent: state => state.event.event.status,
    }),
    isSuccessNameValid() {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    }
  }
}

</script>
